
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'

@Component({
  components: { StockPhotos },
})
  export default class PhotoCell extends Cell {

  }
